.main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 12px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 80vw;
    
}

.header::before,
.header::after {
    content: '';
    display: block;
    width: 50%;
    height: 3px;
    background: var(--text);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.header::before {
    left: 0;
}

.header::after {
    right: 0;
}

.header h2 {
    display: inline-block;
    background-color: var(--bg);
    padding: 0 20px;
    position: relative;
    z-index: 1;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Edmund";
    padding: 12px;
    width: 50vw;
    filter: drop-shadow(0 10px 8px rgba(52, 70, 73, 0.24)) drop-shadow(0 4px 3px rgba(51, 125, 162, 0.4));
    box-shadow: inset 0 1px 1px 0 rgba(148, 163, 184, 0.4);
    border-radius: 4px;
    border: 3px solid var(--text);
}

.statsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px;
}
.statsCard {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 35vw;
    padding: 12px;
    position: relative;
}


.archiveLink {
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;
    text-shadow: 3px 3px 20px #FA75C8;


}

.archiveLink:hover {
    filter: brightness(2);
}

.statsIcon {
    font-size: 16px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--text);
    border-radius: 50%;
}

.statsContent {
    border-bottom: 1px solid var(--text);
    flex: 1;

}

.statsContent h2 {
    margin: 0;
    font-size: 1rem;
}

.statsContent p {
    margin: 2px 0 4px 0;
    font-size: 0.6rem;
}

.featuredContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featuredContainer h2 {
    margin-top: 32px;
    margin-bottom: 2px;
    font-size: 1.2rem;
}

.archiveContainer {
    margin: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    position: relative;
    padding: 24px 0;


}

.archiveContainer img {
    width: 27vw;
    filter: brightness(0.8);
    filter: drop-shadow(0px 3px 16px #03D5DB80);
}

.archiveContent h2 {
    font-size: 1.2rem;
    text-align: center;
}

.archivePlayer {
    display: flex;
    font-size: 0.7rem;
    align-items: center;
    gap: 12px;
    padding: 2px 4px;
    border: 1px solid var(--secondary);
}


.archivePlayer p {
    margin: 4px 2px;
}

.archiveNote {
    font-size: 0.5rem;
    font-style: italic;
}






@media screen and (min-width: 720px) {
    .statsCard {
        width: 18vw;
    }

    .archiveContainer {
        flex-direction: row;
        justify-content: center;
        gap: 48px;
        margin: 72px 0;
    }

    .archiveContainer img {
        width: 25vw;
    }

    .archiveContent h2 {
        font-size: 1.4rem;
    }

    .archivePlayer {
        font-size: 1rem;
    }


}

@media screen and (min-width: 1024px) {
    .statsIcon {
        height: 48px;
        width: 48px;
        font-size: 24px;
    }
    .statsContent h2 {
        font-size: 1.4rem;
    }

    .statsContent p {
        font-size: 0.9rem;
    }
    .statsContent {
        border-width: 2px;
    }

    .statsCard {
        gap: 16px;
    }
    .archiveContainer {
        gap: 108px;
    }
    .archiveContainer img {
        width: 20vw;
    }
    .archiveContent h2 {
        font-size: 2rem;
    }

    .archiveNote {
        font-size: 0.6rem;
    }

    
}
