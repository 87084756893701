.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
}

.header {
    width: 90vw;
    border-bottom: 2px solid var(--text);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header button {
    background: none;
    color: var(--text);
    font-size: 1.1rem;
    font-family: "JetBrains Mono";
    border: none;
    color: var(--primary);
    cursor: pointer;

}

.header button:hover {
    filter: brightness(1.6)
}
.bannerLink {
    text-decoration: none;
    color: var(--text);
}
.banner {
    display: flex;
    align-items: center;
    gap: 12px;
    position: inherit;
    justify-content: center;
    margin: 18px 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 0.9rem;
    cursor: pointer;
}

.banner p {
    margin: 2px 0;
}

.banner:hover {
    filter: brightness(1.2);
}

.nonMobileContent {
    display: none;
}

@media screen and (min-width: 720px) {
    .page {
        overflow: auto;
        height: auto;
        
    }
    .mobileContent {
        display: none;
    }
    .nonMobileContent {
        display: block;
    }
}

