@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
:root {
  --bg: #080b13;
  --bg-light: #094949c0;
  --text: #d5dee3;
  --text-light: #d5dee399;
  --primary: #20dfdf;
  --secondary: #FA75C8;
  --secondary-light: #a1257157;
  --tertiary: #FFBC09;
  --tertiary-light: #cf9b0cd2;
  

}

@font-face {
  font-family: 'Edmund';
  src: url('/src/assets/fonts/Edmund-Italic.woff2') format('woff2'),
      url('/src/assets/fonts/Edmund-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


html {
  background: var(--bg);
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "JetBrains Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text);
  
}


