.main {
    height: 20vh;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.footerContent {
    z-index: 80;
    width: 95%;
    text-align: center;
    font-size: 0.8rem;
    box-sizing: border-box;
    padding: 12px;
    color: var(--text);
}


.footerHighlight {
    color: var(--primary);
}

@media screen and (min-width: 1024px) {
    .main {
        height: 18vh;
    }
}

@media screen and (min-width: 720px) {
    .main {
        height: 24vh;
    }
}