.page {
    min-height: 100vh;
    position: relative;
}

.illuminator {
    display: none;
}
@media screen and (min-width: 1024px) {
    .illuminator {
        display: block;
    }
}