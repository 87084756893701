.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 90vw;
}


.header {
    width: 100%;
    display: flex;
    align-items: center;
}

.header h3 {
    margin: 4px 0;
}

.divider {
    width: 100%;
    height: 2px;
    background: var(--text);
    margin-left: 12px;
}

.note {
    font-size: 0.6rem;
    text-align: left;
    font-style: italic;
    margin: 2px 0;
}

.container {
    margin: 12px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.card {
    height: 48px;
    width: 48px;
    border: 2px solid var(--text);
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    color: 2px solid var(--text);
    transition: border 0.3s;
    background: var(--bg-light);
}


.card:hover {
    border: 2px solid var(--secondary);

}

.icon {
    font-size: 20px;
}


.tooltip {
    background: var(--secondary);
}

.tooltip p {
    font-size: 0.7rem;
    padding: 8px;
    margin: 0;
}



@media screen and (min-width: 720px) {
    .card {
        height: 56px;
        width: 56px;
    }

    .icon {
        font-size: 24px;
    }
 
}

@media screen and (min-width: 1024px) {
    .content {
        width: 80vw;
    }
    .header h3 {
        font-size: 1.6rem;
    }

    .note {
        font-size: 0.8rem;
    }
}