.main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    position: relative;
}

.header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 80vw;

}

.header::before,
.header::after {
    content: '';
    display: block;
    width: 50%;
    height: 3px;
    background: var(--text);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.header::before {
    left: 0;
}

.header::after {
    right: 0;
}

.header h2 {
    display: inline-block;
    background-color: var(--bg);
    padding: 0 20px;
    position: relative;
    z-index: 1;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Edmund";
    padding: 12px;
    width: 50vw;
    filter: drop-shadow(0 10px 8px rgba(52, 70, 73, 0.24)) drop-shadow(0 4px 3px rgba(51, 125, 162, 0.4));
    box-shadow: inset 0 1px 1px 0 rgba(148, 163, 184, 0.4);
    border-radius: 4px;
    border: 3px solid var(--text);
}

.professionalSelected {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--bg) !important;
    filter: drop-shadow(0 10px 8px rgba(52, 70, 73, 0.48)) drop-shadow(0 4px 3px rgba(51, 125, 162, 0.7));
}

.extracurricularSelected {
    background: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: var(--bg) !important;
    filter: drop-shadow(0 10px 8px rgba(73, 52, 66, 0.48)) drop-shadow(0 4px 3px rgba(162, 51, 129, 0.7));
}

.selector_mobile {
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 4px 0 24px 0;
}

.selector_mobile button {
    width: 50%;
    background: none;
    color: var(--text);
    border: 1px solid var(--text);
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 6px;
}



.content_mobile {
    width: 82vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 6px solid var(--text);
    padding: 20px 8px;
    border-bottom: 24px solid var(--text);
    border-radius: 20px;
    gap: 24px;
}

.content_professional {
    border-color: var(--primary) !important;
}

.content_extracurricular {
    border-color: var(--secondary) !important;

}

/* Tablet + Desktop */

.content {
    display: none;
    width: 95vw;
    height: 40vh;
}

.selectorMenu {
    width: 30vw;
    border-left: 6px solid var(--text);
    border-top: 3px solid var(--text);
    padding: 20px 18px;
    border-bottom: 24px solid var(--text);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}


.selectorButtons {
    display: flex;
    gap: 18px;
    width: 100%;
    margin-bottom: 18px;

}

.selectorButtons button {
    width: 50%;
    background: none;
    color: var(--text);
    border: 1px solid var(--text);
    cursor: pointer;
    font-size: 0.8rem;
    padding: 8px 6px;
}

.selectorList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
    box-sizing: border-box;
}


.experienceCard {
    border: 2px solid var(--secondary);
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top: none;
    border-left: none;
    cursor: pointer;
    position: relative;
}

.experienceCardIconContainer {
    display: flex;
    justify-content: flex-end;
}

.experienceCardIcon {
    border-left: 2px solid;
    border-top: 2px solid;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bg)
}

.experienceCard h3 {
    font-size: 0.9rem;
    margin: 2px 0;
}

.experienceCardProfessional .experienceCardIcon {
    border-color: var(--primary);
    background-color: var(--primary);
}

.experienceCardExtracurricular .experienceCardIcon {
    border-color: var(--secondary);
    background-color: var(--secondary);
}

.experienceCard p {
    font-size: 0.7rem;
    margin: 2px 0;
}

.experienceCardProfessional {
    border-color: var(--primary);
}

.experienceCardExtracurricular {
    border-color: var(--secondary);
}

.experienceCardDate {
    font-style: italic;
    opacity: 0.7;
}

.contentContainer {
    height: 100%;
    width: 50vw;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    padding: 0 14px;
}

.contentHeader {
    background: var(--text);
    border-radius: 8px 8px 0 0;
    padding: 10px 12px;
}

.contentHeader h3 {
    margin: 0;
    color: var(--bg);
    font-size: 0.9rem;
}

.contentBody {
    padding: 12px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 48px;
}

.contentBody h2 {
    font-size: 1.1rem;
    margin: 4px 0;
}

.contentBody p {
    font-size: 0.9rem;
    margin: 2px 0;
}

.contentBodyDuration {
    font-style: italic;
    opacity: 0.7;
}

.contentBodySubtitle {
    font-size: 0.8rem !important;
    font-style: italic;
    opacity: 0.7;
}

.contentBodyDetails {
    padding-left: 3rem;
    font-size: 0.9rem;
}
.contentBodyDetails li {
    margin: 8px 0;
}
.contentBodyDetails span {
    vertical-align: text-top;
}





.selectedCard {
    background: var(--bg-light);
    filter: drop-shadow(0 10px 8px rgba(52, 70, 73, 0.48)) drop-shadow(0 4px 3px rgba(48, 92, 114, 0.7));

}


@media screen and (min-width: 720px) {
    .selector_mobile {
        display: none;
    }

    .content_mobile {
        display: none;
    }

    .content {
        display: flex;
        justify-content: center;
        gap: 32px;
    }
}

@media screen and (min-width: 1024px) {
    .content {
        height: 80vh;
    }

    .selectorMenu {
        padding: 24px;
        width: 25vw;
        border-left: 4px solid var(--text);
    }

    .selectorList {
        gap: 28px;
    }

    .selectorButtons button {
        font-size: 1rem;
    }

    .experienceCard h3 {
        font-size: 1rem;
    }

    .experienceCard p {
        font-size: 0.8rem;
    }

    .contentHeader h3 {
        font-size: 1.1rem;
    }

    .contentBody {
        padding: 22px;
    }

    .contentBody h2 {
        font-size: 1.2rem;
    }

    .contentBody p {
        font-size: 1rem;
    }

    .contentBodyDetails {
        font-size: 1rem;
    }
}