.main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 32px 0;
    position: relative;
    min-height: 60vh;
}


.header {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 80vw;

}
.header::before,
.header::after {
    content: '';
    display: block;
    width: 50%;
    height: 3px;
    background: var(--text);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.header::before {
    left: 0;
}

.header::after {
    right: 0;
}

.header h2 {
    display: inline-block;
    background-color: var(--bg);
    padding: 0 20px;
    position: relative;
    z-index: 1;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Edmund";
    padding: 12px;
    width: 50vw;
    filter: drop-shadow(0 10px 8px rgba(52, 70, 73, 0.24)) drop-shadow(0 4px 3px rgba(51, 125, 162, 0.4));
    box-shadow: inset 0 1px 1px 0 rgba(148, 163, 184, 0.4);
    border-radius: 4px;
    border: 3px solid var(--text);
}

.viewer {
    width: 40vw;
    height: 28vw;
    display: none;
    position: relative;
    cursor: pointer;
}

.viewer canvas  {
    height: 100%;
    width: 100%;
}

.cardContainer {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.cardItemLink {
    text-decoration: none;
}

.cardItem {
    width: 100%;
    position: relative;
    background: none;
    border: none;
    color: var(--text);
    text-align: left;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    font-family: "JetBrains Mono" !important;

}

.cardItem:hover {
    filter: brightness(1.8);
}

.cardItem h2 {
    font-size: 1rem;
}

.cardItem p {
    overflow-wrap: break-word;
}




@media screen and (min-width: 720px) {
    .main {
        min-height: 30vh;

    }
    .viewer {
        display: block;
    }
    .content {
        display: flex;
        justify-content: space-around;
        width: 90vw;
        align-items: center;
    }

    .cardContainer {
        width: 46vw;
    }
    
   
}

@media screen and (min-width: 1024px) {

    .main {
        min-height: 20vh;

    }
    .cardContainer {
        width: 36vw;
    }

    .cardItem h2 {
        font-size: 1.2rem;
    }
    
}