.expcard_main {
    border: 2px solid var(--secondary);
    width: 80vw;
    
    box-sizing: border-box;
    border-top: none;
    border-left: none;
    cursor: pointer;
    background: var(--secondary-light);
}

.expcard_main h3 {
    font-size: 1.1rem;
    margin: 2px 0;
}

.expcard_main p {
    font-size: 0.8rem;
    margin: 2px 0;
}

.expcard_professional {
    background: var(--bg-light);
    border-color: var(--primary);
}

.expcard_extracurricular {
    background: var(--secondary-light);
    border-color: var(--secondary);
}

.expcard_date {
    font-style: italic;
    opacity: 0.7;
}

.expcard_content {
    font-size: 0.8rem;
    padding-left: 1.8rem;

}

.expcard_content li {
    margin: 12px 0;
}

.experienceCardIconContainer {
    display: flex;
    justify-content: flex-end;
}

.experienceCardIcon {
    border-left: 2px solid;
    border-top: 2px solid;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bg)
}


.expcard_professional .experienceCardIcon {
    border-color: var(--primary);
    background-color: var(--primary);
}

.expcard_extracurricular .experienceCardIcon {
    border-color: var(--secondary);
    background-color: var(--secondary);
}
